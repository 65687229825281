.container{
  max-width: 400px;
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 50px;
  color: white;

  .msg{
    border-radius: 10px;
    // box-shadow: 0px 5px 10px -5px rgba(36, 8, 13, .2);
    @apply bg-primary shadow-md text-white;
  }
}